$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary:   #032852;
$lightBlue: #006CAD;
$babyBlue:  #6EBBE5;
$orange:    #FF9C00;
$success:   #00AFA7;
$warning:   #FFC107;
$danger:    #DC3545;
$light:     #E8F1F8;

$sidebarBackground:       #1C1F28;
$sidebarBackgroundDarker: #181A21;

$orangeGradient: linear-gradient(0deg, rgba(255,156,0,1) 0%, rgba(227,173,18,1) 100%);

$theme-colors: (
    'primary':      $primary,
    'light-blue':   $lightBlue,
    'baby-blue':    $babyBlue,
    'orange':       $orange,
    'success':      $success,
    'warning':      $warning,
    'danger':       $danger,
    'light':        $light,
);

$sidebar-bg: $sidebarBackground;
$sidebar-minimizer-bg: $sidebarBackgroundDarker;
$link-color: $babyBlue;
$pagination-active-color: $primary;
$component-active-bg: $babyBlue;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
);